import { useAuthStore } from '@/stores/auth'

export default defineNuxtRouteMiddleware(async (to, _) => {
  if (import.meta.server) return
  const auth = useAuthStore()
  await auth.validateTokenFromStorage()
  if (auth.isAuthenticated) {
    return
  }
  if (to.path !== '/auth') {
    return navigateTo('/auth')
  }
})
